// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';


// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};
	
	constructor() {
		this.config = {
			header: {
				self: {},
				items: [
					{
						title: 'Actions',
						root: true,
						icon: 'flaticon-add',
						toggle: 'click',
						translate: 'MENU.ACTIONS',
						submenu: {
							type: 'classic',
							alignment: 'left',
							items: [
								{
									title: 'Create New Post',
									page: '/header/actions',
									icon: 'flaticon-file',
									translate: 'MENU.CREATE_POST',
									aside: {
										self: {
											bullet: 'dot'
										},
										items: [
											{
												section: 'Departments'
											},
											{
												title: 'Resources',
												desc: '',
												icon: 'flaticon-layers',
												bullet: 'dot',
												root: true,
												submenu: [
													{
														title: 'Create New Post',
														page: '/header/actions',
													},
													{
														title: 'Timesheet',
														tooltip: 'Non functional dummy link',
													},
													{
														title: 'Payroll',
														tooltip: 'Non functional dummy link',
													},
													{
														title: 'Contacts',
														tooltip: 'Non functional dummy link',
													}
												]
											}
										]
									}
								},
								{
									title: 'Generate Reports',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-diagram',
									badge: {
										type: 'm-badge--success',
										value: '2'
									},
								},
								{
									title: 'Manage Orders',
									icon: 'flaticon-business',
									submenu: {
										type: 'classic',
										alignment: 'right',
										bullet: 'line',
										items: [
											{
												title: 'Latest Orders',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Pending Orders',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Processed Orders',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Delivery Reports',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Payments',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Customers',
												tooltip: 'Non functional dummy link',
												icon: '',
											}
										]
									}
								},
								{
									title: 'Customer Feedbacks',
									page: '/#',
									icon: 'flaticon-chat-1',
									submenu: {
										type: 'classic',
										alignment: 'right',
										bullet: 'dot',
										items: [
											{
												title: 'Customer Feedbacks',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Supplier Feedbacks',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Reviewed Feedbacks',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Resolved Feedbacks',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Feedback Reports',
												tooltip: 'Non functional dummy link',
												icon: '',
											}
										]
									}
								},
								{
									title: 'Register Member',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-users',
								}
							]
						}
					},
					{
						title: 'Reports',
						root: true,
						icon: 'flaticon-line-graph',
						toggle: 'click',
						translate: 'MENU.REPORTS',
						submenu: {
							type: 'mega',
							width: '1000px',
							alignment: 'left',
							columns: [
								{
									heading: {
										heading: true,
										title: 'Finance Reports',
									},
									items: [
										{
											title: 'Annual Reports',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-map',
										},
										{
											title: 'HR Reports',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-user',
										},
										{
											title: 'IPO Reports',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-clipboard',
										},
										{
											title: 'Finance Margins',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-graphic-1',
										},
										{
											title: 'Revenue Reports',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-graphic-2',
										}
									]
								},
								{
									bullet: 'line',
									heading: {
										heading: true,
										title: 'Project Reports',
									},
									items: [
										{
											title: 'Coca Cola CRM',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title:
												'Delta Airlines Booking Site',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Malibu Accounting',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Vineseed Website Rewamp',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Zircon Mobile App',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Mercury CMS',
											tooltip: 'Non functional dummy link',
											icon: '',
										}
									]
								},
								{
									bullet: 'dot',
									heading: {
										heading: true,
										title: 'HR Reports',
									},
									items: [
										{
											title: 'Staff Directory',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Client Directory',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Salary Reports',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Staff Payslips',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Corporate Expenses',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Project Expenses',
											tooltip: 'Non functional dummy link',
											icon: '',
										}
									]
								},
								{
									heading: {
										heading: true,
										title: 'Reporting Apps',
										icon: '',
									},
									items: [
										{
											title: 'Report Adjusments',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Sources & Mediums',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Reporting Settings',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Conversions',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Report Flows',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Audit & Logs',
											tooltip: 'Non functional dummy link',
											icon: '',
										}
									]
								}
							]
						}
					},
					{
						title: '',
						root: true,
						icon: 'flaticon-paper-plane',
						toggle: 'click',
						translate: 'MENU.APPS',
						badge: {
							type: 'm-badge--brand m-badge--wide',
							value: 'new',
							translate: 'MENU.NEW',
						},
						submenu: {
							type: 'classic',
							alignment: 'left',
							items: [
								{
									title: 'Admin Configuration',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-business',
									submenu: {
										type: 'classic',
										alignment: 'right',
										items: [
											{
												title: 'List of Regions',
												page: 'regions',
												icon: 'flaticon-users',
											},
											{
												title: 'List of Agencies',
												page: 'agencies',
												icon: 'flaticon-users',
											},											
											{
												title: 'List of Brand Categories',
												page: 'brandcategories',
												icon: 'flaticon-users',
											},
											{
												title: 'List of Brand Channels',
												page: 'brandchannels',
												icon: 'flaticon-users',
											},
											{
												title: 'List of Markets',
												page: 'markets',
												icon: 'flaticon-users',
											},
											{
												title: 'List of Brands',
												page: 'brands',
												icon: 'flaticon-users',
											}	,
											{
												title: 'List of Privilages',
												page: 'privilages',
												icon: 'flaticon-users',
											}									
										]
									}
								},
								{
									title: 'App Configuration',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-business',
									submenu: {
										type: 'classic',
										alignment: 'right',
										items: [
											{
												title: 'List of Teams',
												page: 'teams',
												icon: 'flaticon-users',
											},											
											{
												title: 'List of User Roles',
												page: 'roles',
												icon: 'flaticon-users',
											},
											{
												title: 'List of Mapped Roles',
												page: 'rolemap',
												icon: 'flaticon-users',
											},
											{
												title: 'List of Job Status',
												page: 'jobstatus',
												icon: 'flaticon-users',
											},
											{
												title: 'List of Mapped Job Status',
												page: 'job_statusmap',
												icon: 'flaticon-users',
											},
											{
												title: 'List of Job Role Mappings',
												page: 'job_status_role_mapping',
												icon: 'flaticon-users',
											},
											{
												title: 'List of Service Categories',
												page: 'service_categories',
												icon: 'flaticon-users',
											},
											{
												title: 'List of Services',
												page: 'services',
												icon: 'flaticon-users',
											}					
										]
									}
								},
								{
									//title: 'App',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-business',
									submenu: {
										type: 'classic',
										alignment: 'left',
										items: [
											{
												title: 'List of Users',
												page: '/users',
												icon: 'flaticon-users',
											},
											{
												title: 'Calendar',
												page: '/calendar',
												icon: 'flaticon-calendar-2',
											},	
											{
												title: 'List of Tickets',
												page: '/tickets',
												icon: 'flaticon-calendar-2',
											},
											{
												title: 'List of Forecasts',
												page: '/forecasts',
												icon: 'flaticon-calendar-2',
											},	
											{
												title: 'File Upload',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-add',
												badge: {
													type: 'm-badge--danger',
													value: '3'
												}
											},
											{
												title: 'File Attributes',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-signs-1',
											},
											{
												title: 'Folders',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-folder',
											},
											{
												title: 'System Settings',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-cogwheel-2',
											},
											{
												title: 'Library',
												page: '/library',
												icon: 'flaticon-calendar-2',
											},
											{
												title: 'Manage User Manual',
												page: '/ManageUserManual',
												icon: 'flaticon-calendar-2',
											},
											
											{
												title: 'User Manual',
												page: '/user_manual',
												icon: 'flaticon-calendar-2',
											}
										]
									}
								}
							]
						}
					}
				]
			},
			aside: {
				self: {},
				items: [
					// {
					// 	title: 'Dashboard',
					// 	desc: 'Some description goes here',
					// 	root: true,
					// 	icon: 'flaticon-line-graph',
					// 	page: '/',
					// 	// badge: {type: 'm-badge--danger', value: '2'},
					// 	translate: 'MENU.DASHBOARD'
					// },
					// {
					// 	title: 'Users',
					// 	root: true,
					// 	bullet: 'dot',
					// 	icon: 'flaticon-users',
					// 	page: 'users'
					// },
					// {
					// 	title: 'Tickets',
					// 	root: true,
					// 	bullet: 'dot',
					// 	icon: 'flaticon-list-3',
					// 	page: '/tickets'
					// },
					// {
					// 	title: 'Calendar',
					// 	root: true,
					// 	bullet: 'dot',
					// 	icon: 'flaticon-calendar-2',
					// 	page: '/calendar'
					// },
					// {
					// 	title: 'Library',
					// 	root: true,
					// 	bullet: 'dot',
					// 	icon: 'flaticon-cart',
					// 	page: '/library'
					// },
					// {
					// 	title: 'Forecast',
					// 	root: true,
					// 	bullet: 'dot',
					// 	icon: 'flaticon-calendar-with-a-clock-time-tools',
					// 	page: '/forecasts'
					// },
					// {
					// 	title: 'Legacy Data',
					// 	root: true,
					// 	bullet: 'dot',
					// 	icon: 'flaticon-calendar-with-a-clock-time-tools',
					// 	page: '/legacy_data'
					// },
					// {section: 'Configurations'},
					// {
					// 	title: 'Admin Configuration',
					// 	bullet: 'dot',
					// 	icon: 'flaticon-cogwheel-1',
					// 	root: true,
					// 	submenu: [
					// 		{
					// 			title: 'Regions',
					// 			page: 'regions'
					// 		},
					// 		{
					// 			title: 'Agencies',
					// 			page: 'agencies'
					// 		},
					// 		{
					// 			title: 'Brand Categories',
					// 			page: 'brandcategories'
					// 		},
					// 		{
					// 			title: 'Brand Channels',
					// 			page: 'brandchannels'
					// 		},
					// 		{
					// 			title: 'Markets',
					// 			page: 'markets'
					// 		},
					// 		{
					// 			title: 'Brands',
					// 			page: 'brands'
					// 		},
					// 		{
					// 			title: 'Privilages',
					// 			page: 'privilages'
					// 		}
					// 	]
					// },	
					// {
					// 	title: 'App Configuration',
					// 	bullet: 'dot',
					// 	icon: 'flaticon-cogwheel',
					// 	root: true,
					// 	submenu: [
					// 		{
					// 			title: 'Teams',
					// 			page: 'teams'
					// 		},
					// 		{
					// 			title: 'User Roles',
					// 			page: 'roles'
					// 		},
					// 		{
					// 			title: 'Job Status',
					// 			page: 'jobstatus'
					// 		},
					// 		{
					// 			title: 'Role Mapping',
					// 			page: 'rolemap'
					// 		},
					// 		{
					// 			title: 'Job Status Mapping',
					// 			page: 'job_statusmap'
					// 		},
					// 		{
					// 			title: 'Service Categories',
					// 			page: 'service_categories'
					// 		},
					// 		{
					// 			title: 'Services',
					// 			page: 'services'
					// 		}
					// 	]
					// }
				]
			}
		};

		let menu_array = JSON.parse(sessionStorage.getItem('menu'));
		menu_array.forEach(el => {
			if(el.catName == el.serviceName[0].service_name){
				this.config.aside.items.push(
					{
						title: el.catName,
						root: true,
						bullet: 'dot',
						icon: el.iconClass,
						page: '/' + el.serviceName[0].alias_name
					}							
				);
			}
			
		});

		// this.config.aside.items.push(
		// 	{section: 'Configurations'}
		// );

		menu_array.forEach(el => {
			if(el.catName != el.serviceName[0].service_name){
				
				let submenu_array = [];
				el.serviceName.forEach(sub => {
					submenu_array.push(
						{
							title: sub.service_name,
							page: '/' + sub.alias_name
						}
					);
				});

				this.config.aside.items.push(
					{
						title: el.catName,
						bullet: 'dot',
						icon: el.iconClass,
						root: true,
						submenu: submenu_array
					}
				)
			}
			
		});			
	}
	
}
