import { BehaviorSubject, Observable, Subject, from, throwError } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { TokenStorage } from './token-storage.service';
import { UtilsService } from '../services/utils.service';
import { AccessData } from './access-data';
import { Credential } from './credential';

@Injectable()
export class AuthenticationService implements AuthService { 
	API_URL = 'api';
	API_ENDPOINT_LOGIN = '/login';
	API_ENDPOINT_REFRESH = '/refresh';
	API_ENDPOINT_REGISTER = '/register';

	

	public onCredentialUpdated$: Subject<AccessData>;

	constructor(
		private http: HttpClient,
		private tokenStorage: TokenStorage,
		private util: UtilsService
	) {
		this.onCredentialUpdated$ = new Subject();
	}

	/**
	 * Check, if user already authorized.
	 * @description Should return Observable with true or false values
	 * @returns {Observable<boolean>}
	 * @memberOf AuthService
	 */
	public isAuthorized(): Observable<boolean> {
		return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
	}

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. sessionStorage
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		return this.tokenStorage.getAccessToken();
	}

	/**
	 * Get user roles
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		return this.tokenStorage.getUserRoles();
	}
	
	/**
	 * Get USER ID
	 * @returns {Observable<any>}
	 */
	public getUserID(): Observable<any> {
		return this.tokenStorage.getUserID();
	}
	
	/**
	 * Get API URL
	 * @returns {Observable<any>}
	 */
	public getApiURL(): Observable<any> {
		return this.tokenStorage.getApiURL(); 
	}

	/**
	 * Get USER ID
	 * @returns {Observable<any>}
	 */
	public getUserName(): Observable<any> {
		return this.tokenStorage.getUserName();
	}
	
	/**
	 * Get API URL
	 * @returns {Observable<any>}
	 */
	public getUserEmail(): Observable<any> {
		return this.tokenStorage.getUserEmail(); 
	}

	/**
	 * Get API URL
	 * @returns {Observable<any>}
	 */
	public getUserTeam(): Observable<any> {
		return this.tokenStorage.getUserTeam(); 
	}

	/**
	 * Function, that should perform refresh token verifyTokenRequest
	 * @description Should be successfully completed so interceptor
	 * can execute pending requests or retry original one
	 * @returns {Observable<any>}
	 */
	public refreshToken(): Observable<AccessData> {
		return this.tokenStorage.getRefreshToken().pipe(
			switchMap((refreshToken: string) => {
				return this.http.get<AccessData>(this.API_URL + this.API_ENDPOINT_REFRESH + '?' + this.util.urlParam(refreshToken));
			}),
			tap(this.saveAccessData.bind(this)),
			catchError(err => {
				this.logout();
				return throwError(err);
			})
		);
	}

	/**
	 * Function, checks response of failed request to determine,
	 * whether token be refreshed or not.
	 * @description Essentialy checks status
	 * @param {Response} response
	 * @returns {boolean}
	 */
	public refreshShouldHappen(response: HttpErrorResponse): boolean {
		return response.status === 401;
	}

	/**
	 * Verify that outgoing request is refresh-token,
	 * so interceptor won't intercept this request
	 * @param {string} url
	 * @returns {boolean}
	 */
	public verifyTokenRequest(url: string): boolean {
		return url.endsWith(this.API_ENDPOINT_REFRESH);
	}

	/**
	 * Submit login request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	
	public login(credential: Credential): Observable<any> {
		// Expecting response from API
		// {"id":1,"username":"admin","password":"demo","email":"admin@demo.com","accessToken":"access-token-0.022563452858263444","refreshToken":"access-token-0.9348573301432961","roles":["ADMIN"],"pic":"./assets/app/media/img/users/user4.jpg","fullname":"Mark Andre"}
		//return this.http.get<AccessData>(this.API_URL + this.API_ENDPOINT_LOGIN + '?' + this.util.urlParam(credential)).pipe(

		/* Live Server API */
		//https://www.clm-connect.com/JSToolAPI/auth/user_login
		
		/* Staging Server API */
		//https://staging.clm-connect.com/JSToolAPI/auth/user_login

		/* Locla Server Path */
		//http://10.10.9.114/JSToolAPI/auth/user_login

		//http://localhost/JSTool_API/auth/user_login//
		return this.http.post<any>('https://staging.clm-connect.com/JSToolAPI/auth/user_login', credential, { headers: {

			'Content-Type': 'application/x-www-form-urlencoded',
			'Client-Service': 'frontend-client',
			'Auth-Key' : 'simplerestapi'
		}}).pipe(
			map((result: any) => {
				// console.log(result);
				// return;
				if(result.status == 200){
					if (result instanceof Array) {
						return result.pop();
					}
					return result;
				}else{
					return result.status;
				}
				
			}),
			tap(this.saveAccessData.bind(this)),
			catchError(this.handleError('login', []))
		);
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// Let the app keep running by returning an empty result.
			return from(result);
		};
	}

	/**
	 * Logout
	 */
	public logout(refresh?: boolean): void {
		this.tokenStorage.clear();
		if (refresh) {
			location.reload(true);
		}
	}

	/**
	 * Save access data in the storage
	 * @private
	 * @param {AccessData} data
	 */
	private saveAccessData(accessData: AccessData) {
		if (typeof accessData !== 'undefined') {
			this.tokenStorage
				.setUserID(accessData.userID)
				.setAccessToken(accessData.accessToken)
				.setRefreshToken(accessData.refreshToken)
				.setUserRoles(accessData.roles)
				.setApiURL(accessData.apiURL)
				.setUserName(accessData.fullname)
				.setUserEmail(accessData.email)
				.setUserProfilePic(accessData.profile_pic)
				.setUserTeam(accessData.team_name)
				.setMenu(accessData.menu)
				.set_user_privilages(accessData.user_privilages);
			this.onCredentialUpdated$.next(accessData);

		}
	}

	/**
	 * Submit registration request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public register(credential: Credential): Observable<any> {
		return this.http.post('https://staging.clm-connect.com/JSToolAPI/auth/signup', credential, { 
			headers: {
				'Client-Service': 'frontend-client',
				'Auth-Key': 'simplerestapi',
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
			.pipe(catchError(this.handleError('register', []))
		);
	}

	/**
	 * Submit forgot password request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public requestPassword(credential: Credential): Observable<any> {
		return this.http.post('https://staging.clm-connect.com/JSToolAPI/auth/forgot_password', credential, { 
			headers: {
				'Client-Service': 'frontend-client',
				'Auth-Key': 'simplerestapi',
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
			.pipe(catchError(this.handleError('forgot-password', []))
		);
	}

	public checkAuth(): Observable<any> {
		if(sessionStorage.getItem('apiURL')){
			const url = sessionStorage.getItem('apiURL') + `auth/checkAuthOnRefresh`;
			const Headers = new HttpHeaders({
				'User-ID': sessionStorage.getItem('userID'),
				'Authorization': sessionStorage.getItem('accessToken'),
				'Client-Service': 'frontend-client',
				'Auth-Key': 'simplerestapi',
				'Content-Type': 'application/x-www-form-urlencoded'
			});
			
			return this.http.get(url, { headers: Headers});
		}
		
	}

	// READ Teams
	get_teams(): Observable<any> {
		return this.http.get<any>('https://staging.clm-connect.com/JSToolAPI/auth/fetch_teams', { 
			headers: {
				'Client-Service': 'frontend-client',
				'Auth-Key': 'simplerestapi',
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});
	}
	
	// READ Teams
	getSignupData(): Observable<any> {
		return this.http.get<any>('https://staging.clm-connect.com/JSToolAPI/auth/get_signup_data', { 
			headers: {
				'Client-Service': 'frontend-client',
				'Auth-Key': 'simplerestapi',
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});
	}
	// READ Markets
	get_markets(): Observable<any> {
		return this.http.get<any>('https://staging.clm-connect.com/JSToolAPI/auth/fetch_markets', { 
			headers: {
				'Client-Service': 'frontend-client',
				'Auth-Key': 'simplerestapi',
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});
	}
	
	// READ Brand Channels
	get_brand_channels(): Observable<any> {
		return this.http.get<any>('https://staging.clm-connect.com/JSToolAPI/auth/fetch_brand_channels', { 
			headers: {
				'Client-Service': 'frontend-client',
				'Auth-Key': 'simplerestapi',
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});
	}
	
	// READ Brand Category
	get_brand_categories(): Observable<any> {
		return this.http.get<any>('https://staging.clm-connect.com/JSToolAPI/auth/fetch_brand_categories', { 
			headers: {
				'Client-Service': 'frontend-client',
				'Auth-Key': 'simplerestapi',
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});
	}
}