// USA
export const locale = {
	lang: 'en',
	data: {
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		MENU: {
			NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			REPORTS: 'Reports',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard'
		},
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: 'Don\'t have an account?',
				HAVE_ACCOUNT: 'Have an account?',
				SIGNUP_BUTTON: 'Sign Up',
				SIGNIN_BUTTON: 'Sign In',
				FORGOT_BUTTON: 'Forgot Password',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Sign In',
				LOGIN_STATUS: {
					1: 'Your login credentials are not authenticated, please check or contact CLM-Connect Tool administrator.',
					2: 'Your login credentials are not authenticated, please check or contact CLM-Connect Tool administrator.',
					3: 'Your login credentials are not authenticated, please check or contact CLM-Connect Tool administrator.'
				}
			},
			FORGOT: {
				TITLE: 'Forgotten Password?',
				DESC: 'Enter your email to reset your password',
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered. Please check your registered email for further steps.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect'
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION: 'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE: 'Selected customers status have successfully been updated'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created'
				}
			},
			USERS: {
				USERS: 'Users',
				USERS_LIST: 'Users list',
				NEW_USER: 'Add User',
				DELETE_USER_SIMPLE: {
					TITLE: 'User Delete',
					DESCRIPTION: 'Are you sure to permanently delete this user?',
					WAIT_DESCRIPTION: 'User is deleting...',
					4: 'User has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected user',
					5: 'Selected User status have successfully been updated',
					ACTION_CHANGED : 'Status Updated Successfully'
				},
				EDIT: {
					1: 'User has been created',
					2: 'User already existed',
					3: 'User has been updated',
					4: 'Success! Your Password has been changed! Please re-login with new password.',
					6:'Bad Request'
				},
				PROFILE_PIC_UPDATE_STATUS: 'Profile picture successfully updated.',
				PROFILE_PIC_DELETE_STATUS: {
					TITLE: 'Profile picture delete',
					DESCRIPTION: 'Are you sure to permanently delete this profile picture?',
					WAIT_DESCRIPTION: 'Profile picture is deleting...',
					4: 'Profile picture has been deleted'
				}
			},
			MARKETS: {
				MARKETS: 'Markets',
				MARKETS_LIST: 'Markets list',
				NEW_MARKET: 'Add Market',
				DELETE_MARKET_SIMPLE: {
					TITLE: 'Market Delete',
					DESCRIPTION: 'Are you sure to permanently delete this market?',
					WAIT_DESCRIPTION: 'Market is deleting...',
					4: 'Market has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected market',
					5: 'Selected market status have successfully been updated'
				},
				EDIT: {
					1: 'Market has been created',
					2: 'Market already existed',
					3: 'Market has been updated',
					6:'Bad Request'
				}
			},
			LIBRARY: {
				LIBRARY: 'Library',
				LIBRARY_LIST: 'Library list',
				NEW_LIBRARY: 'Add Content',
				DELETE_LIBRARY_SIMPLE: {
					TITLE: 'Delete Content',
					DESCRIPTION: 'Are you sure to permanently delete this content?',
					WAIT_DESCRIPTION: 'Content is deleting...',
					4: 'Content has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected content',
					5: 'Selected market status have successfully been updated'
				},
				EDIT: {
					1: 'Content has been created',
					2: 'Content already existed',
					3: 'Content has been updated'
				}
			},

			USERMANUAL: {
				USERMANUAL: 'Library',
				USERMANUAL_LIST: 'Library list',
				NEW_USERMANUAL: 'Add User Manual',
				DELETE_USERMANUAL_SIMPLE: {
					TITLE: 'Delete User Manual',
					DESCRIPTION: 'Are you sure to permanently delete this content?',
					WAIT_DESCRIPTION: 'Content is deleting...',
					4: 'Content has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected content',
					5: 'Selected market status have successfully been updated'
				},
				EDIT: {
					1: 'User Manual has been created',
					2: 'Content already existed',
					3: 'Content has been updated'
				}
			},




			USER_ROLES: {
				USER_ROLES: 'User Roles',
				USER_ROLES_LIST: 'User Roles list',
				NEW_USER_ROLE: 'Add User Role',
				DELETE_USER_ROLE_SIMPLE: {
					TITLE: 'User Role Delete',
					DESCRIPTION: 'Are you sure to permanently delete this user role?',
					WAIT_DESCRIPTION: 'User Role is deleting...',
					4: 'User Role has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected user role',
					5: 'Selected user role status have successfully been updated'
				},
				EDIT: {
					1: 'User Role has been created',
					2: 'User Role already existed',
					3: 'User Role has been updated'
				}
			},
			ROLE_MAP: {
				ROLE_MAP: 'Role Map',
				ROLE_MAP_LIST: 'Role Map list',
				NEW_ROLE_MAP: 'Add Role Map',
				DELETE_ROLE_MAP_SIMPLE: {
					TITLE: 'Role Map Delete',
					DESCRIPTION: 'Are you sure to permanently delete this role map?',
					WAIT_DESCRIPTION: 'Role Map is deleting...',
					4: 'Role Map has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected role map',
					5: 'Selected role map status have successfully been updated'
				},
				EDIT: {
					1: 'Role Map has been created',
					2: 'Role Map already existed',
					3: 'Role Map has been updated'
				}
			},
			JOB_STATUS_MAP: {
				STATUS_MAP: 'Job Status Map',
				STATUS_MAP_LIST: 'Job Status Map list',
				NEW_STATUS_MAP: 'Add Job Status Map',
				DELETE_STATUS_MAP_SIMPLE: {
					TITLE: 'Job Status Map Delete',
					DESCRIPTION: 'Are you sure to permanently delete this job status map?',
					WAIT_DESCRIPTION: 'Job Status Map is deleting...',
					4: 'Job Status Map has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected job status map',
					5: 'Selected job status map status have successfully been updated'
				},
				EDIT: {
					1: 'Job Status Map has been created',
					2: 'Job Status Map already existed',
					3: 'Job Status Map has been updated'
				}
			},
			REGIONS: {
				REGIONS: 'Regions',
				REGIONS_LIST: 'Regions list',
				NEW_REGION: 'Add Region',
				DELETE_REGION_SIMPLE: {
					TITLE: 'Region Delete',
					DESCRIPTION: 'Are you sure to permanently delete this region?',
					WAIT_DESCRIPTION: 'Region is deleting...',
					4: 'Region has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected region',
					5: 'Selected region status have successfully been updated'
				},
				EDIT: {
					1: 'Region has been created',
					2: 'Region already existed',
					3: 'Region has been updated',
					6: 'Bad Request'
				}
			},
			TEAMS: {
				TEAMS: 'Teams',
				TEAMS_LIST: 'Teams list',
				NEW_TEAM: 'Add Team',
				DELETE_TEAM_SIMPLE: {
					TITLE: 'Team Delete',
					DESCRIPTION: 'Are you sure to permanently delete this team?',
					WAIT_DESCRIPTION: 'Team is deleting...',
					4: 'Team has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected team',
					5: 'Selected team status have successfully been updated'
				},
				EDIT: {
					1: 'Team has been created',
					2: 'Team already existed',
					3: 'Team has been updated'
				}
			},
			SERVICE_CATEGORY: {
				SERVICE_CATEGORY: 'Services Categories',
				SERVICE_CATEGORY_LIST: 'Services Categories list',
				NEW_SERVICE_CATEGORY: 'Add Service Category',
				DELETE_SERVICE_CATEGORY_SIMPLE: {
					TITLE: 'Service Category Delete',
					DESCRIPTION: 'Are you sure to temporarily delete this Services Categories?',
					WAIT_DESCRIPTION: 'Service Category is deleting...',
					4: 'Service Category has been deleted'
				},
				TRASH_SERVICE_CATEGORY_SIMPLE: {
					TITLE: 'Service Category Trash',
					DESCRIPTION: 'Are you sure to permanently delete this Services Categories?',
					WAIT_DESCRIPTION: 'Service Category is deleting...',
					4: 'Service Category has been Trashed'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected Service Category',
					5: 'Selected service category status have successfully been updated'
				},
				EDIT: {
					ADD_MESSAGE : 'Service Category has been created',
					EXITS_MESSAGE : 'Service Category already existed',
					UPDATE_MESSAGE : 'Service Category has been updated'
				}
			},
			SERVICE: {
				SERVICE: 'Services',
				SERVICE_LIST: 'Services list',
				NEW_SERVICE: 'New Service',
				DELETE_SERVICE_SIMPLE: {
					TITLE: 'Service Delete',
					DESCRIPTION: 'Are you sure to temporarily delete this Service?',
					WAIT_DESCRIPTION: 'Service is deleting...',
					4: 'Service has been deleted'
				},
				TRASH_SERVICE_SIMPLE: {
					TITLE: 'Service Trash',
					DESCRIPTION: 'Are you sure to permanently delete this Service?',
					WAIT_DESCRIPTION: 'Service is deleting...',
					4: 'Service has been Trashed'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected Service',
					5: 'Selected service status have successfully been updated'
				},
				EDIT: {
					ADD_MESSAGE : 'Service has been created',
					EXITS_MESSAGE : 'Service already existed',
					UPDATE_MESSAGE : 'Service has been updated'
				}
			},
			BRANDS: {
				BRANDS: 'Brands',
				BRANDS_LIST: 'Brands list',
				NEW_BRAND: 'Add Brand',
				DELETE_BRAND_SIMPLE: {
					TITLE: 'Brand Delete',
					DESCRIPTION: 'Are you sure to permanently delete this brand?',
					WAIT_DESCRIPTION: 'Brand is deleting...',
					4: 'Brand has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected brand',
					5: 'Selected brand status have successfully been updated'
				},
				EDIT: {
					ADD_MESSAGE: 'Brand has been created',
					EXITS_MESSAGE: 'Brand already existed',
					UPDATE_MESSAGE: 'Brand has been updated',
					6: 'Bad Request'
				}
			},
			EMAIL:{
				Email: 'Email',
                EDIT: {
                    2: 'Email send successfully'
                }
			},
			PRIVILAGES: {  
				PRIVILAGES: 'Privilages',
				PRIVILAGES_LIST: 'Privilages list',
				NEW_PRIVILAGES: 'Add Privilages',
				DELETE_PRIVILAGES_SIMPLE: {
					TITLE: 'Privilage Delete',
					DESCRIPTION: 'Are you sure to permanently delete this Privilage?',
					WAIT_DESCRIPTION: 'Privilages is deleting...',
					4: 'Privilages has been deleted'
				},
				DELETE_TRASH_SIMPLE: {
					TITLE: 'Privilage Trash',
					DESCRIPTION: 'Are you sure to permanently delete this Trash?',
					WAIT_DESCRIPTION: 'Privilages is Trashing...',
					4: 'Privilages has been Trashed'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected Privilages',
					5: 'Selected Privilages status have successfully been updated'
				},
				EDIT: {
					1: 'Privilages has been created',
					2: 'Privilages already existed',
					3: 'Privilages has been updated'
				}
			},
			BRAND_CATEGORIES: {
				BRAND_CATEGORIES: 'Brand Categories',
				BRAND_CATEGORY_LIST: 'Brand Categories list',
				NEW_BRAND_CATEGORY: 'Add Brand Category',
				DELETE_BRAND_CATEGORY_SIMPLE: {
					TITLE: 'Brand Category Delete',
					DESCRIPTION: 'Are you sure to permanently delete this brand category?',
					WAIT_DESCRIPTION: 'Brand Category is deleting...',
					4: 'Brand Category has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected brand category',
					5: 'Selected brand category status have successfully been updated'
				},
				EDIT: {
					1: 'Brand Category has been created',
					2: 'Brand Category already existed',
					3: 'Brand Category has been updated',
					6: "Bad Request"
				}
			},
			BRAND_CHANNELS: {
				BRAND_CHANNELS: 'Brand Channels',
				BRAND_CHANNEL_LIST: 'Brand Channels list',
				NEW_BRAND_CHANNEL: 'Add Brand Channel',
				DELETE_BRAND_CHANNEL_SIMPLE: {
					TITLE: 'Brand Channel Delete',
					DESCRIPTION: 'Are you sure to permanently delete this brand channel?',
					WAIT_DESCRIPTION: 'Brand Channel is deleting...',
					4: 'Brand Channel has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected brand channel',
					5: 'Selected brand channel status have successfully been updated'
				},
				EDIT: {
					1: 'Brand Channel has been created',
					2: 'Brand Channel already existed',
					3: 'Brand Channel has been updated',
					6: "Bad Request"
				}
			},
			AGENCIES: {
				AGENCIES: 'Agencies',
				AGENCY_LIST: 'Agencies list',
				NEW_AGENCY: 'Add Agency',
				DELETE_AGENCY_SIMPLE: {
					TITLE: 'Agency Delete',
					DESCRIPTION: 'Are you sure to permanently delete this agency?',
					WAIT_DESCRIPTION: 'Agency is deleting...',
					4: 'Agency has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected agency',
					5: 'Selected agency status have successfully been updated'
				},
				EDIT: {
					1: 'Agency has been created',
					2: 'Agency already existed',
					3: 'Agency has been updated',
					6:'Bad Request'
				}
			},
			EMAIl: {
				Email: 'Email',
				EMAIl_LIST: 'Email list',
				NEW_EMAIl: 'Add Email',
				DELETE_EMAIl_SIMPLE: {
					TITLE: 'Email Delete',
					DESCRIPTION: 'Are you sure to permanently delete this email?',
					WAIT_DESCRIPTION: 'email is deleting...',
					4: 'Email has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected email',
					5: 'Selected email status have successfully been updated'
				},
				EDIT: {
					1: 'Email has been created',
					2: 'Email send successfully ',
					3: 'Email has been updated'
				}
			},
			CHANNEL_MAP: {
				CHANNEL_MAP: 'Channel Map',
				CHANNEL_MAP_LIST: 'Channel Map list',
				NEW_CHANNEL_MAP: 'Add Channel Map',
				DELETE_CHANNEL_MAP_SIMPLE: {
					TITLE: 'Channel Map Delete',
					DESCRIPTION: 'Are you sure to permanently delete this role map?',
					WAIT_DESCRIPTION: 'Channel Map is deleting...',
					4: 'Channel Map has been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected role map',
					5: 'Selected role map status have successfully been updated'
				},
				EDIT: {
					1: 'Channel Map has been created',
					2: 'Channel Map already existed',
					3: 'Channel Map has been updated'
				}
			},
		},
		TICKETS: {
			TICKETS: 'Tickets',
			TICKET_LIST: 'Tickets list',
			NEW_TICKET: 'Add Ticket',
			SAVE_BUTTON: 'Submit',
			DELETE_TICKET_SIMPLE: {
				TITLE: 'Ticket Delete',
				DESCRIPTION: 'Are you sure to permanently delete this ticket?',
				WAIT_DESCRIPTION: 'Ticket is deleting...',
				4: 'Ticket has been deleted'
			},
			DELETE_MULTI_BRAND_SIMPLE: {
				TITLE: 'Row Delete',
				DESCRIPTION: 'Are you sure to permanently delete this row?',
				WAIT_DESCRIPTION: 'Row is deleting...',
				4: 'Row has been deleted'
			},
			DELETE_TICKET_DRAFT: {
				TITLE: 'Ticket Draft Delete',
				DESCRIPTION: 'Are you sure to permanently delete this draft?',
				WAIT_DESCRIPTION: 'Ticket draft is deleting...',
				4: 'Ticket draft has been deleted'
			},
			UPDATE_STATUS: {
				TITLE: 'Status has been updated for selected ticket',
				5: 'Selected ticket status have successfully been updated'
			},
			EDIT: {
				1: 'Ticket has been created',
				2: 'Ticket already existed',
				3: 'Ticket has been updated'
			},
			DRAFT: {
				1: 'Ticket Draft has been created',
				2: 'Ticket Draft has been Updated',
			},
			MULTIBRAND: {
				1: 'Slide Metrics has been created',
				2: 'Slide Metrics has been Updated',
			},
			DRAFTLOGS: {
				TITLE: 'Ticket Draft has been created',
			},
			LOGS: {
				TITLE: 'Ticket Logs',
			},
			ERROR: {
				1: 'Global Master is already Created for this Brand',
				2: 'Please enter the valid inputs to submit the ticket(s)',
			}
		},
		LEGACYDATA: {
			LEGACYDATA : 'Legacy Data',
			LEGACYDETAILS : 'Legacy Details'
		},
		FORECASTS: {
			FORECASTS: 'Forecasts',
			FORECASTS_LIST: 'Forecasts list',
			NEW_FORECASTS: 'Add Forecast',
			DELETE_FORECASTS_SIMPLE: {
				TITLE: 'Forecast Delete',
				DESCRIPTION: 'Are you sure to permanently delete this forecast?',
				WAIT_DESCRIPTION: 'Forecast is deleting...',
				4: 'Forecast has been deleted'
			},
			UPDATE_STATUS: {
				TITLE: 'Status has been updated for selected forecast',
				5: 'Selected forecast status have successfully been updated'
			},
			EDIT: {
				1: 'Forecast has been created',
				2: 'Forecast already existed',
				3: 'Forecast has been updated'
			},
			IMPORT : {
				1: 'Forecast has been Imported',
				2: 'Forecast already existed',
				3: 'Forecast has been updated'
			}
		},
        SITEMAP: {
			SITEMAPS: 'Sitemaps',
			DELETE_SITEMAP_SIMPLE: {
				TITLE: 'Sitemap Delete',
				DESCRIPTION: 'Are you sure to permanently delete this sitemap?',
				WAIT_DESCRIPTION: 'Sitemap is deleting...',
				4: 'Sitemap has been deleted'
			},			
			EDIT: {
				1: 'Sitemap has been created',
				2: 'Sitemap already existed',
				3: 'Sitemap has been updated'
			}
		},
		RAG: {
			RAGS: 'Rags',
			DELETE_RAG_SIMPLE: {
				TITLE: 'Rag Delete',
				DESCRIPTION: 'Are you sure you want to delete this slide? Deleting a slide marked RED by the category team could result in this presentation being marked as local.',
				WAIT_DESCRIPTION: 'Rag is deleting...',
				4: 'Rag has been deleted'
			},	
			EXPORT_RAG: {
				TITLE : 'Export RAG',
				DESCRIPTION: "Please ensure you have PDF reader application installed in your laptop and set the printer value to Adobe PDF. Any issues to download the file as PDF, please reach 'DC.Cx-Supports@haleon.com' for further support.",
				WAIT_DESCRIPTION: 'Please wait...'
			},		
			EDIT: {
				1: 'Rag has been created',
				2: 'Rag already existed',
				3: 'Rag has been updated'
			}
		},
		JOB_STATUS: {
			JOB_STATUS: 'Job Status',
			NEW_JOB_STATUS: 'Add Job Status',
			DELETE_JOB_STATUS_SIMPLE: {
				TITLE: 'Job Status Delete',
				DESCRIPTION: 'Are you sure to permanently delete this Job Status?',
				WAIT_DESCRIPTION: 'Job Status is deleting...',
				4: 'Job Status has been deleted'
			},			
			EDIT: {
				1: 'Job Status has been created',
				2: 'Job Status already existed',
				3: 'Job Status has been updated'
			}
		},
         STATUS: {
            400: 'Bad request',
            401: 'Unauthorized',
            500: 'Internal server error'
        },
	}
};
