import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { stringify } from '@angular/compiler/src/util';

@Injectable()
export class TokenStorage {
	/**
	 * Get USER ID
	 * @returns {Observable<string>}
	 */
	public getUserID(): Observable<string> {
		const id: string = <string>sessionStorage.getItem('userID');
		return of(id);
	}

	/**
	 * Get access token
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		const token: string = <string>sessionStorage.getItem('accessToken');
		return of(token);
	}

	/**
	 * Get refresh token
	 * @returns {Observable<string>}
	 */
	public getRefreshToken(): Observable<string> {
		const token: string = <string>sessionStorage.getItem('refreshToken');
		return of(token);
	}

	/**
	 * Get user roles in JSON string
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		const roles: any = sessionStorage.getItem('userRole');
		try {
			return of(roles);
		} catch (e) {}
	}
	
	/**
	 * Get API URL
	 * @returns {Observable<string>}
	 */
	public getApiURL(): Observable<string> {
		const url: string = <string>sessionStorage.getItem('apiURL');
		return of(url);
	}

	/**
	 * Get user roles in JSON string
	 * @returns {Observable<any>}
	 */
	public getUserEmail(): Observable<any> {
		const email: any = sessionStorage.getItem('userEmail');
		return of(email);
	}
	
	public getUserTeam(): Observable<any> {
		const team_name: any = sessionStorage.getItem('teamName');
		return of(team_name);
	}
	/**
	 * Get API URL
	 * @returns {Observable<string>}
	 */
	public getUserName(): Observable<string> {
		const name: string = <string>sessionStorage.getItem('userName');
		return of(name);
	}

	/**
	 * Set USER ID
	 * @returns {TokenStorage}
	 */
	public setUserID(id: string): any {
		sessionStorage.setItem('userID', id);

		return this;
	}

	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessToken(token: string): TokenStorage {
		sessionStorage.setItem('accessToken', token);

		return this;
	}

	/**
	 * Set refresh token
	 * @returns {TokenStorage}
	 */
	public setRefreshToken(token: string): TokenStorage {
		sessionStorage.setItem('refreshToken', token);

		return this;
	}

	/**
	 * Set user roles
	 * @param roles
	 * @returns {TokenStorage}
	 */
	public setUserRoles(roles: any): any {
		if (roles != null) {
			sessionStorage.setItem('userRole', roles);
		}

		return this;
	}
	
	/**
	 * Set API URL
	 * @param url
	 * @returns {TokenStorage}
	 */
	public setApiURL(url: string): any {
		sessionStorage.setItem('apiURL', url);

		return this;
	}

	public setUserName(name: string): any {
		sessionStorage.setItem('userName', name);

		return this;
	}

	public setUserEmail(email: string): any {
		sessionStorage.setItem('userEmail', email);

		return this;
	}
	
	public setUserProfilePic(profile_pic: string): any {
		sessionStorage.setItem('userProfilePic', profile_pic);

		return this;
	}

	public setUserTeam(team_name: string): any {
		sessionStorage.setItem('teamName', team_name);

		return this;
	}

	
	public set_user_privilages(user_privilages: any): any {
		sessionStorage.setItem('user_privilages', JSON.stringify(user_privilages));
		return this;
	}
	
	public setMenu(menu: any): any {
		sessionStorage.setItem('menu', JSON.stringify(menu));
		return this;
	}

	/**
	 * Remove tokens
	 */
	public clear() {
		sessionStorage.removeItem('userID');
		sessionStorage.removeItem('accessToken');
		sessionStorage.removeItem('refreshToken');
		sessionStorage.removeItem('userRole');
		sessionStorage.removeItem('apiURL');
		sessionStorage.removeItem('userName');
		sessionStorage.removeItem('userEmail');
		sessionStorage.removeItem('teamName');
		sessionStorage.removeItem('user_privilages');
		sessionStorage.removeItem('menu');
	}
}
