import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
	selector: 'm-user-profile',
	templateUrl: './user-profile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit {
	@HostBinding('class')
	// tslint:disable-next-line:max-line-length
	classes = 'm-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light';

	@HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';

	@Input() avatar: string = './assets/app/media/img/users/user4.jpg';
	@Input() avatarBg: SafeStyle = '';
	time_stamp: Date = new Date();

	@ViewChild('mProfileDropdown') mProfileDropdown: ElementRef;

	user_name:string = sessionStorage.getItem('userName');
	user_email:string = sessionStorage.getItem('userEmail');
	apiURL:string = sessionStorage.getItem('apiURL');
	user_profile_pic:string = sessionStorage.getItem('userProfilePic');

	// Privilage based access
	service_crud: any;
	user_id: string;
	UMC: number;
	UMR: number;
	UMU: number;
	UMD: number;

	constructor (
		private router: Router,
		private authService: AuthenticationService,
		private sanitizer: DomSanitizer
	) {}

	ngOnInit (): void {
		this.service_crud = JSON.parse(sessionStorage.getItem('user_privilages'));
		this.service_crud.forEach(element => {
			if(element.alias_name === "user_manual")
			{
				this.UMC = element.C;
				this.UMR = element.R;
				this.UMU = element.U;
				this.UMD = element.D;
			}
		})
		
		// if (!this.avatarBg)
			// this.avatarBg = this.sanitizer.bypassSecurityTrustStyle('url(./assets/app/media/img/misc/user_profile_bg.jpg)');
	}

	public logout () {
		this.router.navigate(['/login']);
		this.authService.logout();
		
	}
}
